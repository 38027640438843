import { makeStyles } from '@material-ui/core/styles';

import Colours from '../../../../Theme/Colours';

const useStyles = makeStyles({
  row: {
    color: Colours.Backgrounds.white,
    display: 'inline-grid',
    gridAutoFlow: 'column',
    gridGap: '6px',
    gridTemplateColumns: 'auto auto',
    paddingBottom: '3px',
    paddingTop: '3px',
    '& > *': {
      margin: 'auto',
      lineHeight: '36px',
    },
    '&.inActive': {
      opacity: '50%',
    },
  },
  avatar: {
    '&.selectedAvatar': {
      border: '2px solid #0BA360',
    },
    width: '24px',
    height: '24px',
    marginRight: '6px',
    marginLeft: '6px',
  },
  status: {
    '&.Available': {
      color: '#0BA360',
    },
    '&.AvailableMobile': {
      color: '#0BA360',
    },
    '&.Busy': {
      color: '#e392f7',
    },
    '&.Away': {
      color: '#F69D1B',
    },
  },
  icon: {
    position: 'relative',
    paddingLeft: '8px',
    fontWeight: 900,
    fontSize: '24px',
    color: '#0BA360',
  },
});

export default { useStyles };
