import React, { forwardRef, useCallback, useRef, useEffect, useState } from 'react';
import Card from '@material-ui/core/Card';
import InputBase from '@material-ui/core/InputBase';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Typography, { TypographyVariant } from '../../../Typography';
import Button, { ButtonVariant } from '../../../Button';

import { NumberPadProps, KeyboardKeys } from './NumberPad.types';
import NumberPadThemer from './NumberPad.theme';
import { faDeleteLeft } from '@fortawesome/pro-solid-svg-icons';
import SaleforceService from "../../../../Services/SaleforceService";

const ThemedTextField = withStyles(NumberPadThemer.textFieldClasses)(InputBase);

const NumberPadCardComponent = forwardRef<HTMLElement, NumberPadProps>((props, ref) => {
  const {
    id,
    className,
    style,
    number,
    onBackspaceClick,
    callbackValue,
    showBackSpace,
    onNumberChange,
    disableFocus,
    onEscape,
    contactName,
    contactCount = 0,
    showContactName,
    onCursorPositionChange,
  } = props;

  const inputRef: React.MutableRefObject<any> = useRef(null);

  const [escClickedOnce, setEscClickedOnce] = useState(false);

  useEffect(() => {
    const handleEsc = (nativeEvent: any) => {
      const event = nativeEvent;
      if (event.key === KeyboardKeys.esc && escClickedOnce) onEscape?.();
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [escClickedOnce, onEscape]);

  const classes = NumberPadThemer.useStyles();
  const cardClassName = classnames(classes.card, className);
  const backSpaceClassName = classnames(classes.backspaceIcon);
  const iconClassName = classnames(classes.iconElement);

  const handleClick = useCallback(
    (e: any) => {
      onBackspaceClick?.(callbackValue, e);
    },
    [onBackspaceClick, callbackValue]
  );

  const updateSelectionStart = useCallback(() => {
    onCursorPositionChange?.(inputRef.current.selectionStart);
  }, [onCursorPositionChange]);

  const handleOnKeyDown = useCallback(
    (e: any) => {
      const key = e.key || '';
      const keysToInclude =
        key === KeyboardKeys.plus ||
        key === KeyboardKeys.backSpace ||
        key === KeyboardKeys.hash ||
        key === KeyboardKeys.asterisk ||
        key === KeyboardKeys.arrowLeft ||
        key === KeyboardKeys.arrowRight;

      //Prevent default action, which is inserting character
      if (disableFocus) {
        if (e.preventDefault) e.preventDefault();
        e.returnValue = false;
      } else if (key === KeyboardKeys.esc) {
        if (e.preventDefault) e.preventDefault();
        e.target.blur();
        setEscClickedOnce(true);
      } else if (key === KeyboardKeys.spaceBar) {
        // Not the most elegant way to do it
        if (e.repeat) {
          e.target.value = `${e.target.value}+`;
        } else {
          e.target.value += '0';
        }
      } else if (isNaN(key) && !keysToInclude) {
        if (e.preventDefault) e.preventDefault();
        e.returnValue = false;
      }
    },
    [disableFocus]
  );

  const handleOnFocus = useCallback(() => {
    setEscClickedOnce(false);
  }, [setEscClickedOnce]);

  const didPressedMoreContacts = () => {
    if (number) {
      SaleforceService.callingObjectNavigation(number, '', true)
    }
  };
  const backspaceIcon = (
    <div onClick={handleClick} className={backSpaceClassName}>
      <FontAwesomeIcon icon={faDeleteLeft} style={{color: "#ffffff",}} className={iconClassName} />
    </div>
  );
  const numberField = (
    <ThemedTextField
      id={`${id}-Number-Field`}
      inputRef={inputRef}
      type="tel"
      value={number}
      fullWidth
      autoFocus
      onChange={onNumberChange}
      onSelect={updateSelectionStart}
      onKeyDown={handleOnKeyDown}
      onFocus={handleOnFocus}
      inputProps={{ autoComplete: 'off' }}
      autoComplete="off"
    />
  );

  const contactNameNode = (
    <div>
      <Typography.P1 id={`${id}-Contact-Name`} variant={TypographyVariant.light} className={classnames(classes.contactName, {[classes.contactNameWithMargin]: contactCount <= 1})}>
        {contactName}
      </Typography.P1>
      {contactCount > 1 && (
        <Button
          id={`${id}-Contact-Count`}
          style={{ color: '#FFFFFF', marginRight: 16}}
          className={classes.contactName}
          variant={ButtonVariant.text}
          onClick={didPressedMoreContacts}
        >
          {`(${contactCount - 1} other contacts)`}
        </Button>
      )}
    </div>
  );

  return (
    <Card id={id} ref={ref} className={cardClassName} style={style}>
      <div className={classes.mainContainer}>
        <div className={classes.inputContainer}>
          {numberField}
          {showBackSpace && backspaceIcon}
        </div>
        {(showContactName && !!contactName) && contactNameNode}
      </div>
    </Card>
  );
});

NumberPadCardComponent.defaultProps = {
  className: undefined,
  numberTextClassName: undefined,
  style: undefined,
  number: undefined,
  onBackspaceClick: () => null,
  callbackValue: undefined,
  showBackSpace: false,
  disableFocus: false,
  onEscape: () => {},
  contactName: '',
  contactCount: 0,
  showContactName: false,
};

export default NumberPadCardComponent;
