import React, { forwardRef, useState, useEffect } from 'react';
import FilledInput from '@material-ui/core/FilledInput';
import { PopoverOrigin } from '@material-ui/core/Popover';
import { withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import classnames from 'classnames';

import Select, { SelectTheme, SelectVariant } from '../../../Select';

import { NumberDropdownProps } from './NumberDropdown.types';
import NumberDropdownThemer from './NumberDropdown.theme';

const DefaultAnchorOrigin: PopoverOrigin = { vertical: 'bottom', horizontal: 'left' };
const DefaultTransformOrigin: PopoverOrigin = { vertical: 'top', horizontal: 'left' };

const ThemedFilledInput = withStyles(NumberDropdownThemer.filledInputClasses)(FilledInput);

const resolveInitialValue = (menuItems: any[] | undefined) => {
  let result = (menuItems && menuItems[0]?.value) || '';
  menuItems?.forEach((item) => {
    if (item?.selected) {
      result = item?.value;
    }
  });
  return result;
};

const NumberDropdown = forwardRef<HTMLDivElement, NumberDropdownProps<any>>((props, ref) => {
  const { id, menuItems, dropdownDisabled, onDropDownSelect } = props;

  const initialValue = resolveInitialValue(menuItems);
  const [dataState, setDataState] = useState(initialValue);

  useEffect(() => {
    onDropDownSelect?.(initialValue);
  }, []);

  const handleChange = (event: any) => {
    setDataState(event.target.value);
    onDropDownSelect?.(event.target.value);
  };

  const classes = NumberDropdownThemer.useStyles(props);
  const themeClassName = classnames(classes.default, classes.inputContainer, classes.inputText);

  const dropdownIcon = dropdownDisabled ? NotInterestedIcon : ExpandMoreIcon;

  return (
    <Select
      id={id}
      ref={ref}
      className={themeClassName}
      menuItemClassName={classes.dropdownItemStyles}
      formControlClassName={classes.inputContainer}
      inputElement={<ThemedFilledInput />}
      menuItems={menuItems}
      value={dataState}
      defaultValue={initialValue}
      onChange={handleChange}
      getMenuItemValue={(item) => item.value}
      getMenuItemLabel={(item) => item.label}
      isMenuItemSelected={(item) => item.selected}
      isMenuItemDisabled={(item) => item.disabled}
      theme={SelectTheme.dark}
      variant={SelectVariant.filled}
      iconComponent={dropdownIcon}
      menuProps={{
        classes: { paper: classes.dropdownMenuStyle, list: classes.dropdownListStyle },
        anchorOrigin: DefaultAnchorOrigin,
        transformOrigin: DefaultTransformOrigin,
        getContentAnchorEl: null,
        PopoverClasses: { root: classes.popoverRootStyle },
      }}
      disabled={dropdownDisabled}
    />
  );
});

NumberDropdown.propTypes = {
  menuZIndex: 1300 as any,
};

export default NumberDropdown;
