import React from 'react';

import { CallState } from '../../Enums/Call.types';
import { ContactStatus } from '../../Enums/Contact.types';

export interface NavigationDiallerProps<MenuItem, WrapUpActions> {
  id: string;
  className?: string;
  closeButtonClassName?: string;
  menuZIndex?: any;
  style?: React.CSSProperties;
  callState: CallState;
  isCallRecording: boolean;
  isMicMute: boolean;
  isOnHold: boolean;
  contactNumbers: Array<MenuItem>;
  moreNumbers?: Array<MenuItem>;
  showMoreNumber?: boolean;
  moreNumberOpened?: boolean;
  onDropDownSelect?: (callbackValue?: any, event?: any) => any;
  showBackSpace?: boolean;
  callButtonDisabled?: boolean;
  dialpadButtonDisabled?: boolean;
  recordingButtonDisabled?: boolean;
  onBackspaceClick?: (callbackValue?: any, event?: any) => any;
  onDialpadNumberPressed?: (callbackValue?: any, event?: any) => any;
  onNumberChanged?: (callbackValue?: any, event?: any) => any;
  numberCallbackValue?: any;
  number?: string;
  showDialpad?: boolean;
  contactName?: string;
  contactCount?: number
  callTime?: string;
  closeButtonDisabled?: boolean;
  onCallClicked: (callbackValue?: any) => any;
  onHangUp: (callbackValue?: any) => any;
  onCallRecord: (callbackValue?: any) => any;
  onAddBookmark: (callbackValue?: any) => any;
  onDialpadClicked: (callbackValue?: any) => any;
  onCallHold: (callbackValue?: any) => any;
  onCallMute: (callbackValue?: any) => any;
  onCallForwardButtonClicked: (callbackValue?: any) => any;
  showCallCenterButton?: boolean;
  onGoToCallCenter: (callbackValue?: any) => any;
  onGoToWrapUp: (callbackValue?: any) => any;
  onClose: (callbackValue?: any) => any;
  getMoreNumberValue: (number?: any) => any;
  getMoreNumberIcon: (number?: any) => React.ReactNode;
  isMoreNumberSelected: (number?: any) => boolean;
  onMoreNumberPanelExpand: (moreNumberOpened?: boolean) => any;
  onMoreNumberItemClick: (number?: any) => any;
  showCallForwardCard: boolean;
  hideNumberPad?: boolean;
  title: string;
  proceedButtonLabel: string;
  proceedButtonDisabled?: boolean;
  inputValue?: string;
  onCallForwardCardClose?: () => any;
  onInputChange?: (event?: any) => any;
  dataLoading?: boolean;
  onProceed?: () => any;
  onSelect?: (item?: any) => any;
  data?: Array<any>;
  dataTotalCounts?: number;
  getId: (item: any) => string;
  getAvatarUrl: (item: any) => string | null;
  getAvatarFallbackLabel: (item: any) => string;
  getContactName: (item: any) => string;
  getContactStatus: (item: any) => ContactStatus;
  isSelected: (item: any) => boolean;
  isActive: (item: any) => boolean;
  dialpadOpen: boolean;
  externalNumber: string;
  externalNumberCallbackValue?: any;
  dialpadProceedButtonDisabled?: boolean;
  onUseDialpadClicked: () => any;
  onCallForwardNumberChange: (event?: any) => any;
  onCallForwardNumberPressed: (callbackValue?: any, event?: any) => any;
  onCallForwardBackSpaceClicked: (callbackValue?: any, event?: any) => any;
  onCallForwardBackButtonClicked: () => any;
  onDialpadProceed: (callbackValue?: any, event?: any) => any;
  onCursorPositionChange?: (position: any) => any
}

export const NumberDropDownDisabledMap = {
  [CallState.dialing]: false,
  [CallState.connecting]: true,
  [CallState.ringing]: true,
  [CallState.answered]: true,
  [CallState.ended]: true,
  [CallState.disconnected]: false,
};

export const ContactViewVisibleMap = {
  [CallState.dialing]: false,
  [CallState.connecting]: true,
  [CallState.ringing]: true,
  [CallState.answered]: true,
  [CallState.ended]: true,
  [CallState.disconnected]: false,
};

export const ContactNameViewVisibleMap = {
  [CallState.dialing]: true,
  [CallState.connecting]: false,
  [CallState.ringing]: false,
  [CallState.answered]: false,
  [CallState.ended]: false,
  [CallState.disconnected]: true,
};

export const CallButtonVisibleMap = {
  [CallState.dialing]: true,
  [CallState.connecting]: false,
  [CallState.ringing]: false,
  [CallState.answered]: false,
  [CallState.ended]: false,
  [CallState.disconnected]: true,
};

export const CallControlButtonsVisibleMap = {
  [CallState.dialing]: false,
  [CallState.connecting]: true,
  [CallState.ringing]: true,
  [CallState.answered]: true,
  [CallState.ended]: true,
  [CallState.disconnected]: false,
};
