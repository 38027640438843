import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  contactList: {
    paddingLeft: '24px',
    maxHeight: '320px',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      background: 'transparent',
      width: '6px'
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'rgba(255, 255, 255, 0.2)',
      borderRadius: '30px'
    }
  },
  contactRow: {
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.1)',
      paddingLeft: '24px',
      marginLeft: '-24px',
      cursor: 'pointer'
    }
  }
});

export default { useStyles };