import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  callOutcomeCard: {
    margin: 0,
    width: '100%',
    maxWidth: 420,
  },
  callOutcomeWrapper: {
    maxWidth: 420,
    // maxHeight: 556, we have to uncomment it after bringback call AI summary
    maxHeight: 560,
    margin: 'auto', 

    // Remove below styles after bringing back call AI summary
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: '#FFFFFF',
  },
  callOutcome: {
    maxHeight: 488,
    overflowY: 'scroll',

    '&::-webkit-scrollbar': {
      width: '6px',
      marginRight: 6,
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(209, 212, 216, 1)',
      borderRadius: 30,
      border: 'none',
    }
  },
  callOutcomeWithBanner: {
    maxHeight: 524,
  },
  callOutcomeRecordCard: {
    display: 'flex',
    flexDirection: 'column',
    padding: '8px 12px',
  },
  callOutcomeRecordCardHeader: {
    marginBottom: 8,
  },
  callOutcomeRecordCardBody: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: 42,
    width: 385
  },
  callOutcomeRecordLeftWrapper: { display: 'flex' },
  callOutcomeRecordStatusBadge: {
    display: 'flex',
    alignItems: 'center',
  },
  callOutcomeRecordContact: {
    marginLeft: 16,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  actionButton: {
    borderRadius: 'unset !important',
    height: '72px !important',
    color: '#FFFFFF',
    backgroundColor: 'rgba(0, 25, 50, 1)',
    '&:hover': {
      backgroundColor: 'rgba(0, 25, 50, 0.8)',
    },
    '&:active': {
      backgroundColor: 'rgba(0, 25, 50, 0.9)',
    },
    '&:focus': {
      backgroundColor: 'rgba(0, 25, 50, 0.9)',
    },
    '&:disabled': {
      backgroundColor: 'rgba(0, 25, 50, 0.8) !important',
      color: 'rgba(255, 255, 255, 0.5)',
    },
  },
  avatar: { width: 36, height: 36 },
  callMediumIcon: {
    backgroundColor: '#4c94ba',
    width: 32,
    height: 32,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#FFFFFF',
  },
  columnTimestamp: {
    flex: 1,
    minWidth: 62,
    maxWidth: '22%',
    marginLeft: 16,
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  timestampText: { maxWidth: '100%' },
});

const useCallStatusBadgeStyles = makeStyles({
  badge: { bottom: 0, right: 0 },
});

export default { useStyles, useCallStatusBadgeStyles };
