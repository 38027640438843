import moment from 'moment';

import { store } from '../app/store';
import CallConstants from '../constants/Call';

class CommunicationService {
  static formatCallTime = (totalSeconds: number) => {
    const time = moment.duration({ s: totalSeconds });
    const timeFormatted = moment().startOf('day').add(time).format('mm:ss');

    return timeFormatted;
  }

  // Ths method returns the Team Id of the team number selected
  // static getTeamContextForOutbound = () => {
  //   const phoneNumberId = authState.teamWithOutGoingPreference(store.getState())?.data?.outgoingCallIdPreference?.phoneNumberId;
  //   const callerIdType = authState.teamWithOutGoingPreference(store.getState())?.data?.outgoingCallIdPreference?.callerIdType;
  //   const userTeams = authState.userTeams(store.getState());
  //   const assignedTeam = userTeams?.length > 0 ? userTeams?.[0] : null;
  //   let result = null;

  //   if (callerIdType === CallConstants.OutgoingCallIdEnums.Team && phoneNumberId === assignedTeam?.id) {
  //     result = assignedTeam?.id;
  //   }

  //   return result;
  // }

  static getOutgoingPhoneNumber = () => {

    const userPreferences = store.getState()?.auth?.user?.preferences;
    const phoneNumberId = userPreferences?.outgoingCallIdPreference?.phoneNumberId;
    const callerIdType = userPreferences?.outgoingCallIdPreference?.callerIdType;
    const tenantSettings = store.getState()?.settings?.tenantSettings;
    const user = store.getState()?.auth?.user;
    const userTeam = store.getState()?.auth?.userTeam;
    const assignedTeam = userTeam ?? null;
    let result = null;

    if (callerIdType === CallConstants.OutgoingCallIdEnums.Organisation && phoneNumberId === tenantSettings?.phoneNumber?.id) {
      result = tenantSettings?.phoneNumber?.number;
    } else if (callerIdType === CallConstants.OutgoingCallIdEnums.Team && phoneNumberId === assignedTeam?.id) {
      result = assignedTeam?.number?.number;
    } else if (callerIdType === CallConstants.OutgoingCallIdEnums.Personal && (phoneNumberId === user?.phone?.number || phoneNumberId === '')) {
      result = user?.phone?.number;
    } else if (callerIdType === CallConstants.OutgoingCallIdEnums.LocalPresence && phoneNumberId === '') {
      result = CallConstants.OutgoingCallIdEnums.LocalPresence;
    } else {
      result = tenantSettings?.phoneNumber?.number;
    }

    if (!result) {
      result = user?.phone?.number || user?.mobilePhone?.number || tenantSettings?.phoneNumber?.number;
    }
    // TODO: Get Outgoing call number

    return result;
  }

  // static getActiveCallEvents = (campaignId = null) => {
  //   const scheduledCalls = diallerSelectors.scheduledCalls(store.getState())?.data;
  //   const campaignCallEvents = diallerSelectors.campaignDataOfId(store.getState(), campaignId)?.callEvents;
  //   const selectedCallEvents = campaignId ? campaignCallEvents : scheduledCalls;

  //   return selectedCallEvents;
  // }

  // static initiateCallOnNextCall = (campaignId = null) => {
  //   // const scheduledCalls = diallerSelectors.scheduledCalls(store.getState())?.data;
  //   const campaignCallEvents = diallerSelectors.campaignDataOfId(store.getState(), campaignId)?.callEvents;
  //   // const selectedCallEvents = campaignId ? campaignCallEvents : scheduledCalls;

  //   if (campaignCallEvents?.length > 0) {
  //     const callEventToCall = campaignCallEvents[0];
  //     const callEventId = callEventToCall?.callEventId;
  //     const selectedContact = callEventToCall?.contact;
  //     const number = callEventToCall?.contact?.phone || callEventToCall?.contact?.mobilePhone;
  //     const dialFromNumber = this.getOutgoingPhoneNumber();

  //     OutboundCallService.initiateOutboundCallWithGdpr(selectedContact, number, dialFromNumber, callEventId, campaignId);
  //   }
  // }
}

export default CommunicationService;
