import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import UserService from "../Services/User/UserService";
import TeamService from "../Services/Team/TeamService";
import { RootState } from '../app/store';

const initialState: any = { 
  authUser: {}, 
  user: {}, 
  userTeam: {}, 
  teamWithOutGoingPreference: {}, 
  userWrapUpStatus: {}, 
  lastCallRecord: null,
};

export const getUser: any = createAsyncThunk(
  "getUser/get",
  async (userId: any, { getState }) => {
    const currentState = getState() as RootState;
    const { tenantCode, AccessToken } = currentState.auth.authUser;
    const result = await UserService.getUser(userId, tenantCode, AccessToken);

    return result.data;
  }
);

export const getUserTeam: any = createAsyncThunk(
  "getUserTeam/get",
  async (userId: any, { getState }) => {
    const currentState = getState() as RootState;
    const { tenantCode, AccessToken } = currentState.auth.authUser;
    const result = await UserService.getUserTeam(userId, tenantCode, AccessToken);

    return result.data;
  }
);

export const updateUserStatus: any = createAsyncThunk(
  "updateUserStatus/put",
  async (userStatuses: any, { getState }) => {
    const currentState = getState() as RootState;
    const { tenantCode, AccessToken } = currentState.auth.authUser;
    const { id } = currentState.auth.user;
    const result = await UserService.updateUserAvailabilityStatus(id, tenantCode, AccessToken, userStatuses);

    return result.data;
  }
);

export const setUserOutboundCallerId: any = createAsyncThunk(
  "setUserOutboundCallerId/post",
  async (callerInfo: any, { getState }) => {
    const currentState = getState() as RootState;
    const { tenantCode, AccessToken } = currentState.auth.authUser;
    const { id } = currentState.auth.user;
    const result = await UserService.setUserOutboundCallerId(id, tenantCode, AccessToken, callerInfo);

    return result.data;
  }
);

export const getTeamWithOutGoingCallIdPreference: any = createAsyncThunk(
  "getTeamWithOutGoingCallIdPreference/get",
  async (_, { getState }) => {
    const currentState = getState() as RootState;
    const { tenantCode, AccessToken } = currentState.auth.authUser;
    const { id } = currentState.auth?.userTeam;
    const result = await TeamService.getTeam(id, tenantCode, AccessToken);

    return result.data;
  }
);

export const getUserWrapupStatus: any = createAsyncThunk(
  "getUserWrapupStatus/get",
  async (userId: any, { getState }) => {
    const currentState = getState() as RootState;
    const { tenantCode, AccessToken } = currentState.auth.authUser;
    const result = await UserService.getUserWrapupStatus(userId, tenantCode, AccessToken);

    return result.data;
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    addAuthInfo(state, action: PayloadAction<any>) {
      state.authUser = action.payload;
    },
    addLastCallRecord(state, action: PayloadAction<any>) {
      state.lastCallRecord = action.payload;
    },
    updateUserAvailabilityStatus(state, action: PayloadAction<any>) {
      const { userStatus, available } = action.payload;
      state.user.userStatus = userStatus;
      state.user.available = available;
    },
    refreshToken(state, action: PayloadAction<any>) {
      const { accessToken, refreshToken } = action.payload;
      state.authUser.AccessToken = accessToken;
      state.authUser.RefreshToken = refreshToken;
    },
  },
  extraReducers: {
    // User
    [getUser.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.user = data;
    },
    [getUser.rejected]: (state, action) => {
      state.user.error = action.error;
    },

    // userTeam
    [getUserTeam.fulfilled]: (state, action) => {
      state.userTeam = action.payload.data?.[0];
    },
    [getUserTeam.rejected]: (state, action) => {
      state.userTeam.error = action.error;
    },

    // User status
    [updateUserStatus.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.user.userStatus = data?.userStatus;
    },
    [updateUserStatus.rejected]: (state, action) => {
      state.user.userStatus = action.error;
    },

    // User Caller Id
    [setUserOutboundCallerId.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.user.preferences.OutgoingCallIdPreference = data?.preferences?.OutgoingCallIdPreference;
    },
    [setUserOutboundCallerId.rejected]: (state, action) => {
      state.user.preferences.OutgoingCallIdPreference = null;
    },
    // Team with outgoing call id preferences
    [getTeamWithOutGoingCallIdPreference.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.teamWithOutGoingPreference = data;
    },
    [getTeamWithOutGoingCallIdPreference.rejected]: (state, action) => {
      state.teamWithOutGoingPreference = action.error;
    },

    // User WrapUp Status
    [getUserWrapupStatus.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.userWrapUpStatus = data;
    },
    [getUserWrapupStatus.rejected]: (state, action) => {
      state.userWrapUpStatus.error = action.error;
    },
  } 
});

export const { addAuthInfo, addLastCallRecord, updateUserAvailabilityStatus, refreshToken } = authSlice.actions;
export default authSlice.reducer;
