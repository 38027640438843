import ApiHelper from '../../helpers/apiHelper';
import VoiceIQApiAxiosInstance from '../instances/VoiceIQAPI';
import { store } from '../../app/store';

const getUser = (userId: string, tenantCode: string, AccessToken: string) => {
  const config = {
    baseURL: store.getState().auth?.authUser?.RPAPIGW_URL,
    headers: ApiHelper.createHeader(AccessToken, tenantCode),
    url: `/api/v1/user/${userId}`,
    method: 'get',
  };

  return VoiceIQApiAxiosInstance.request(config);
};

const getUserTeam = (userId: string, tenantCode: string, AccessToken: string) => {
  const config = {
    baseURL: store.getState().auth?.authUser?.RPAPIGW_URL,
    headers: ApiHelper.createHeader(AccessToken, tenantCode),
    url: `/api/v1/teams?userId=${userId}`,
    method: 'get',
  };

  return VoiceIQApiAxiosInstance.request(config);
};

const updateUserAvailabilityStatus = (userId: string, tenantCode: string, AccessToken: string, userStatus: any) => {
  const config = {
    baseURL: store.getState().auth?.authUser?.RPAPIGW_URL,
    headers: ApiHelper.createHeader(AccessToken, tenantCode),
    url: `/api/v1/userstatus/${userId}/availabilityStatus`,
    method: 'put',
    data: userStatus,
  };

  return VoiceIQApiAxiosInstance.request(config);
};

const getUserWrapupStatus = (userId: string, tenantCode: string, AccessToken: string) => {
  const config = {
    baseURL: store.getState().auth?.authUser?.RPAPIGW_URL,
    headers: ApiHelper.createHeader(AccessToken, tenantCode),
    url: `/api/v1/user/${userId}/wrapupstatus`,
    method: 'get',
  };

  return VoiceIQApiAxiosInstance.request(config);
};

const setUserOutboundCallerId = (userId: string, tenantCode: string, AccessToken: string, callerInfo: any) => {
  const config = {
    baseURL: store.getState().auth?.authUser?.RPAPIGW_URL,
    headers: ApiHelper.createHeader(AccessToken, tenantCode),
    url: `/api/v1/user/${userId}/callerId`,
    method: 'post',
    data: callerInfo,
  };

  return VoiceIQApiAxiosInstance.request(config);
};
/*
HTTP POST METHODdo
Route: https://qaapigw.rocketphone.ai/api/v1/user/1b273651-f951-4e08-8d3b-f570f2b444d2/callerId
here 1b273651-.. is the userID
In the requestBody
{
  "PhoneNumberId": "89848d7a-15a2-42cf-90ad-ca1de9882a86",
  "CallerIdType": "Personal"
}
*/

const UserService = { getUser, getUserTeam, updateUserAvailabilityStatus, getUserWrapupStatus, setUserOutboundCallerId };

export default UserService;